export default [

  //优惠券

  {

    suctype:1,

    title:'优惠券创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加优惠券',

      path:'/market/coupon/index',

      name:'coupon',

    },

    operation2:{

      type:2,

      text:'复制这张优惠券',

      path:'/market/coupon/editDiscountTicketDetail',

    },

    operation3:{

      type:3,

      text:'返回优惠券管理列表',

      path:'/market/coupon/index',

    },

    other:[

      {

        title:'生成唯一券码',

        describe:'生成优惠券唯一领取二维码，将二维码图片随着发货单一起打印，客户收货包裹后扫描二维码领取优惠券，提高复购率',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnxBlAoLCPfe6EUEQROHNvTN',

        type:2,
        
        buttontext:'查看券码生成方法 >'

      },

      {

        title:'给新会员赠送优惠券',

        describe:'前往客户运营-会员等级配置页，设置会员升级礼包，实现：会员开通成功后，赠送优惠券，引导新会员即时消费',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnWjRDDwrayFzcCiMj8m96de',

        type:2,
        
        buttontext:'查看新会员送券配置说明 >'

      },
      {

        title:'组合营销',

        describe:'优惠券支持与满减送、转盘抽奖等营销活动相结合，通过各方式发放',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnsrdAs8KE0uJTuKuRom0H6c',

        type:2,
        
        buttontext:'查看更多优惠券发放方式 >'

      },
      {

        title:'权益吸引，私域涨粉',

        describe:'设置加入社群赠送优惠券，在主推活动落地页、商城首页、商品详情页等入口放置社群企业微信二维码，通过权益引导客户加入社群',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnuuXi0UQUgHiRRoTZQyLPsf',

        type:2,
        
        buttontext:'查看实现方法 >'

      },

    ]

  },

  //满减送

  {

    suctype:2,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/discountActivity/index',

      name:'discountActivity',

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/discountActivity/editOverMoney',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/discountActivity/index',

    },

    other:[

      {

        title:'活动与会员机制相结合',

        describe:'利用满减送，实现会员日营销，例如：会员日1分钱领取日常售价20元商品，且活动仅会员可参加',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnHaYG5Lk0EoU3YQmJOweD6n',

        type:2,
        
        buttontext:'查看活动配置方法 >'

      },

      {

        title:'推广新品',

        describe:'例如：在商城内消费满99元，赠送新品试用装，从而提高客户对新品的认知率，打开新品销量',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnDXkozeoCcHlA4lqjKuoXme',

        type:2,
        
        buttontext:'查看活动配置方法 >'

      },
      {

        title:'清理库存',

        describe:'针对商城内的滞销品，设置满减活动，解决库存积压问题',

        link:'/market/discountActivity/index',

        type:1,
        
        buttontext:'去设置 >'

      },

    ]

  },

  //限时折扣

  {

    suctype:3,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/addDiscount',

      name:'addDiscount'

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/addDiscount',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/limitedDiscount',

    },

    other:[

      {

        title:'搭配满减送，提升客单',

        describe:'节日大促期间，搭配满减送活动，叠加消费者福利，限时折扣优惠烘托抢购氛围，满N元赠送或满N件赠送提升客单，',

        link:'/market/discountActivity/index',

        type:1,
        
        buttontext:'去试试 >'

      },

      {

        title:'商城活动预热和曝光',

        describe:'1.商城首页添加限时折扣装修模块，选择对应的限时折扣活动，进行活动预热，客户进入商城，还可预约活动；2.设置活动标签，活动期间，客户浏览商品、分享商品、生成分享图片等多种行为，可见活动标签，大大提高活动曝光',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnN4mLChWqcUlfiMyP72BPGf',

        type:2,
        
        buttontext:'查看配置方法 >'

      },

    ]

  },

  //打包一口价

  {

    suctype:4,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/packPrice/editPriceDetaile',

      name:'editPriceDetaile'

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/packPrice/editPriceDetaile',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/packPrice/index',

    },
  },

  //支付有礼

  {

    suctype:5,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/payGifts/editPayGiftsDetail',

      name:'editPayGiftsDetail'

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/payGifts/editPayGiftsDetail',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/payGifts',

    },

  },

  //多人拼团

  {

    suctype:6,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/sellGroupBox/editsellGroupDetail',

      name:'editsellGroupDetail'

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/sellGroupBox/editsellGroupDetail',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/sellGroupBox/sellGroup',

    },

    other:[

      {

        title:'团长优惠',

        describe:'拼团活动设置团长优惠，团长将享受更优惠的价格，促进开团率',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnGbeLf4NNZJ44GCOnfM1sWh',

        type:2,
        
        buttontext:'查看设置方法 >'

      },

      {

        title:'模拟成团+一键参团',

        describe:'1.开启模拟成团，成团有效期内人数未满的团，系统将会模拟“匿名买家”凑满人数，使该团成团，提高商城成团率，减少拼团订单丢失；2.开启一键参团，客户进入拼团活动商品详情页，可选择任一团参团，提高参团率',

        link:'https://jusnn6k8al.feishu.cn/docs/doccnWLATxiIPqZliba6fXkt6td',

        type:2,
        
        buttontext:'查看示例 >'

      },

      {

        title:'开启拼团抽奖',

        describe:'开启拼团抽奖玩法，例如10人团，组团成功仅6人可拼中活动商品，剩余4个退款并赠送优惠券等权益。',

        link:'/market/spellgroupbuy/details',

        query:{
          sucpage:true,
        },

        type:1,
        
        buttontext:'去设置  >'

      },

    ]

  },

  //助力领取

  {

    suctype:7,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/Assistoreceive/Editassistindex',

      name:'Editassistindex'

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/Assistoreceive/Editassistindex',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/Assistoreceive/Assistindex',

    },

  },

  //转盘抽奖

  {

    suctype:8,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/lottery/editlotteryDetail',

      name:'editlotteryDetail'

    },

    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/lottery/editlotteryDetail',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/lottery',

    },

    other:[

      {

        title:'支付有礼+转盘抽奖',

        describe:'支付有礼活动与转盘抽奖组合搭配，客户在商城内消费成功，引导客户前往参与抽奖，一方面增加客户在商城内的停留时长；奖品可设置优惠券、积分等，还可提高复购',

        link:'/market/payGifts/editPayGiftsDetail',
        
        query:{id:0,type:2,status:0},

        type:1,
        
        buttontext:'去试试 >'

      },
    ]
  },
  
  //员工活码

  {

    suctype:9,

    title:'活码创建成功',

    operation1:{

      type:1,

      text:'继续添加活码',

      path:'/qyCustomer/callmeedit',

      name:'callmeedit'

    },

    operation3:{

      type:3,

      text:'返回活码管理列表',

      path:'/qyCustomer/callme',

    },

    other:[

      {

        title:'结合让利涨粉，利用权益吸引客户',

        describe:'商品让利涨粉，配置客户首次添加好友成功后，客户可获得的权益；当客户浏览商品时，吸引客户添加员工企微好友，成为企业微信客户',

        link:'https://jusnn6k8al.feishu.cn/docs/doccn034RSVU0WSY8hxTmG7e7ib#32hdVD',

        type:2,
        
        buttontext:'查看示例 >'

      },

      {

        title:'支付成功引导加粉',

        describe:'可以设置在客户支付成功后，引导客户加粉，为后续更好的给客户提供售后跟进与服务',

        link:'https://jusnn6k8al.feishu.cn/docs/doccn034RSVU0WSY8hxTmG7e7ib#DeKisN',

        type:2,
        
        buttontext:'查看示例 >'

      },
      
    ]

  },
  
  //助力pro

  {

    suctype:10,

    title:'活动创建成功',

    subTitle:'下载推广小程序码，立即推广，获得更多客流和订单',

    buttontext:'立即推广',

    operation1:{

      type:1,

      text:'继续添加活动',

      path:'/market/helpPro/details',

      name:'helpProdetails'

    },
    
    operation2:{

      type:2,

      text:'复制这场活动',

      path:'/market/helpPro/details',

    },

    operation3:{

      type:3,

      text:'返回活动管理列表',

      path:'/market/helpPro',

    },

    other:[]

  }

]