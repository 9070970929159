<template>
	<div style="padding:0px">
		<div v-if="!msg.issuccess">
			<slot></slot>
		</div>
		<div v-else>
			<el-card class="positioncard" body-style="height:100%">
				<div class="flexfull" style="height:100%">
					<div class="iconbox">
						<i class="el-icon-success"></i>
					</div>
					<div class="titlebox" v-if="thispagemsg.title">
						{{thispagemsg.title}}
					</div>
					<div class="subTitlebox" v-if="thispagemsg.subTitle">
						{{thispagemsg.subTitle}}
					</div>
					<div class="buttontextbox" v-if="thispagemsg.buttontext">
						<el-button type="primary" @click="promote">
							{{thispagemsg.buttontext}}
						</el-button>
					</div>
					<div class="operationbox">
						<div class="flexRow" v-if="!!thispagemsg.operation1">
							<el-button type="text" @click="nextpush(thispagemsg.operation1)">
								{{thispagemsg.operation1.text}}</el-button>
							<div class="line"></div>
						</div>
						<div class="flexRow" v-if="!!thispagemsg.operation2">
							<el-button type="text" @click="copythis(thispagemsg.operation2)">
								{{thispagemsg.operation2.text}}</el-button>
							<div class="line"></div>
						</div>
						<div class="flexRow" v-if="!!thispagemsg.operation3">
							<el-button type="text" @click="returnlist(thispagemsg.operation3)">
								{{thispagemsg.operation3.text}}</el-button>
							<!-- <div class = "line"></div> -->
						</div>
					</div>
					<div class="flexRow" v-if="thispagemsg.other&&thispagemsg.other.length">
						<div class="crossline"></div>
						<span style="margin:0px 30px;color:#303133;font-size:14px">你还可以</span>
						<div class="crossline"></div>
					</div>
					<div class="graybox" v-if="thispagemsg.other&&thispagemsg.other.length">
						<div class="otherbox flexRow" v-for="(v,i) in thispagemsg.other" :key="i">
							<div class="other-left fonthidden2">
								<span style="font-weight:bold;color:#303133">{{v.title}}：</span>
								{{v.describe}}
							</div>
							<div style="flex:1;text-align:right">
								<el-button type="text" @click="jump(v)">{{v.buttontext}}</el-button>
							</div>
						</div>
					</div>
				</div>
			</el-card>
		</div>

		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeShow = false"></batch-cods>

	</div>
</template>

<script>
	import batchCods from "@/components/bathCodes"
	import arrmsg from "./creatsuccess.js"
	export default {
		components: {
			batchCods,
		},
		props: {
			msg: {
				type: Object,
				default: () => {
					return {}
				}
			},
		},
		watch:{
			msg(){
				if (this.msg.issuccess){
          // console.log(this.thispagemsg.operation3.path)
					sessionStorage.setItem('addRouter', this.thispagemsg.operation3.path?this.thispagemsg.operation3.path:'');
				}
			}
		},
		data() {
			return {
				thispagemsg: {},
				weiCodeShow: false,
				weiCodeData: {},
			}
		},
		created() {
			var name = sessionStorage.getItem('addRouter');
      // console.log(name)
			if (name){
				this.$router.replace({
					path: name
				})
				sessionStorage.setItem('addRouter', '');
			}
			
			this.thispagemsg = arrmsg.find((v) => {
				return v.suctype == this.msg.suctype
			})
			// console.log(location.origin+location.pathname+'#'+this.thispagemsg.operation3.path)
		},
		mounted() {},
		methods: {
			test(e) {
				// console.log(11111111111111111111111111111111)
				// console.log(this.thispagemsg.operation3.path)
				// this.$router.push({
				//   path:this.thispagemsg.operation3.path
				// })
				location.href = location.origin + location.pathname + '#' + this.thispagemsg.operation3.path
			},
			//继续添加
			nextpush(e) {
				// console.log(e, this.msg)
				if ([1, 2].includes(this.msg.suctype)) {
					this.$router.push({
						name: e.name,
						params: {
							type: this.msg.type
						}
					})
				} else if ([3, 4, 5, 6, 7, 8, 9 ,10].includes(this.msg.suctype)) {
					this.$emit('recovery')
				}
        sessionStorage.setItem('addRouter', '');
			},
			//复制
			copythis(e) {
				if ([1].includes(this.msg.suctype)) {
					this.$router.push({
						path: e.path,
						query: {
							type: this.msg.type,
							copyId: this.msg.id
						}
					})
				} else if ([2].includes(this.msg.suctype)) {
					this.$router.push({
						path: e.path,
						query: {
							activeType: this.msg.type,
							copyId: this.msg.id
						}
					})
				} else if ([3, 4].includes(this.msg.suctype)) {
					this.$router.push({
						path: e.path,
						query: {
							copy: 1,
							id: this.msg.id
						}
					})
				} else if ([5, 6, 7].includes(this.msg.suctype)) {
					this.$router.push({
						path: e.path,
						query: {
							type: 2,
							Id: this.msg.id,
							status: '未开始'
						}
					})
				} else if ([8].includes(this.msg.suctype)) {
					this.$router.push({
						path: e.path,
						query: {
							type: 4,
							id: this.msg.id,
							status: 1
						}
					})
				}else if ([10].includes(this.msg.suctype)) {
					this.$router.push({
						path: e.path,
						query: {
							Id: this.msg.id,
							copy:true
						}
					})
				}
        sessionStorage.setItem('addRouter', '');
			},
			//返回列表
			returnlist(e) {
				this.$router.push({
					path: e.path,
				})
        sessionStorage.setItem('addRouter', '');
			},
			//你还可以...
			jump(v) {
				// console.log(this.msg)
				if (v.type == 2) {
					window.open(v.link)
				} else if (v.type == 1) {
					//
					let query = v.query ? v.query : {}
					if(this.msg.suctype == 6){
						query = {
							...v.query,
							openId:this.msg.id
						}
					}
					this.$router.push({
						path: v.link,
						query: query
					})
				}
			},
			//推广
			promote() {
				if (this.msg.suctype == 1) {
					this.handleTablePromote1(this.msg.id)
				} else if (this.msg.suctype == 2) {
					this.handleTablePromote2(this.msg.id)
				} else if (this.msg.suctype == 3) {
					this.handleTablePromote3(this.msg.id)
				} else if (this.msg.suctype == 4) {
					this.handleTablePromote4(this.msg.id)
				} else if (this.msg.suctype == 5) {
					this.weiCodeFun1(this.msg.id)
				} else if (this.msg.suctype == 6) {
					this.weiCodeFun2(this.msg)
				} else if (this.msg.suctype == 7) {
					this.weiCodeFun3(this.msg)
				} else if (this.msg.suctype == 8) {
					this.handleTablePromote5(this.msg.id)
				}else if (this.msg.suctype == 10) {
					this.weiCodeShow = true;
					let uid = this.getRamNumber()
					this.weiCodeData = {
						Page: 'pageC/pages/helpPro/helpPro',
						Scene: `id=${this.msg.id}`
					}
				}
			},

			getRamNumber() {
				var result = '';
				for (var i = 0; i < 6; i++) {
					result += Math.floor(Math.random() * 16).toString(16);
				}

				return result;
			},
			// 推广
			handleTablePromote1(id) {
				this.weiCodeShow = true;
				let uid = this.getRamNumber()
				this.weiCodeData = {
					Page: 'pages/r/r',
					Scene: `id=${id}&uid=${uid}`
				}
			},
			handleTablePromote2(id) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: 'pages/f/f',
					Scene: `id=${id}`
				}
			},
			handleTablePromote3(id) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: 'pages/f/f',
					Scene: `id=${id}`
				}
			},
			handleTablePromote4(id) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: 'pages/f/f',
					Scene: `id=${id}`
				}
			},
			weiCodeFun1(Id) {
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/f/f`,
					Scene: `id=${Id}`
				}
			},
			weiCodeFun2(record) {
				let fullId = record.id
				let proId = record.ProductId
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pages/groupDetail/groupDetail`,
					Scene: `fid=${fullId}&id=${proId}`,
					InputVaule: 'pages/groupDetail/groupDetail?fullId=' + fullId + '&id=' + proId
				}
			},
			weiCodeFun3(record) {

				let fullId = record.id
				// let proId = record.ProductId
				this.weiCodeShow = true;
				this.weiCodeData = {
					Page: `pageA/pages/activity/help/list`,
					Scene: `activityId=${fullId}`,
					// InputVaule: 'pages/groupDetail/groupDetail?fullId=' + fullId + '&id=' + proId
				}
			},
			handleTablePromote5(id) {
				this.weiCodeShow = true;
				let uid = this.getRamNumber()
				this.weiCodeData = {
					Page: 'pageA/pages/activity/truntable/truntable',
					Scene: `id=${id}`
				}
			},
		},
		beforeDestroy() {
			// window.removeEventListener('beforeunload', e => this.test(e))
      sessionStorage.setItem('addRouter', '');
		}
	}
</script>

<style lang="less" scoped>
	.flexRow {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.fonthidden2 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.positioncard {
		position: absolute;
		top: 10px;
		bottom: 10px;
		width: 100%;

		.flexfull {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.iconbox {
				font-size: 70px;
				color: #67C23A;
			}

			.titlebox {
				font-size: 16px;
				font-weight: bold;
				color: #303133;
				line-height: 1.5;
				margin: 20px 0px 40px 0px
			}

			.subTitlebox {
				font-size: 14px;
				font-weight: 400;
				color: #606266;
				line-height: 1.5;
			}

			.buttontextbox {
				margin: 20px 0px
			}

			.operationbox {
				margin: 20px 0px 40px 0px;
				display: flex;
				flex-direction: row;
				align-content: center;

				.line {
					width: 0px;
					height: 10px;
					opacity: 1;
					border: 1px solid #DCDFE6;
					margin: 0px 20px;
					display: block;
				}
			}

			.crossline {
				width: 167px;
				height: 1px;
				opacity: 1;
				/* border: 1px solid #DCDFE6; */
				background: #DCDFE6;
			}

			.graybox {
				width: 70%;
				padding: 10px;
				background: #F7F8FA;
				border-radius: 2px 2px 2px 2px;
				opacity: 1;
				margin-top: 30px;

				.otherbox {
					/* border:1px solid black; */
					margin: 10px 0px;

					.other-left {
						font-size: 14px;
						font-weight: 400;
						color: #606266;
						line-height: 1.3;
						max-width: 80%;
						/* border:1px solid black; */
					}
				}
			}
		}
	}
</style>
